<template>
  <div class="electronicMap">
    <!-- 查询 -->
    <div class="selectBox">
      <el-select
        collapse-tags
        multiple
        clearable
        filterable
        @change="changeLine"
        v-model="companyIds"
        placeholder="选择线路"
        ref="lineSelect"
      >
        <el-option
          v-for="item in lineOptions"
          :key="item.companyId"
          :disabled="loading"
          :label="item.companyName"
          :value="item.companyId"
        ></el-option>
      </el-select>

      <el-select
        collapse-tags
        multiple
        clearable
        filterable
        @change="changeBus"
        v-model="busIds"
        placeholder="选择车辆"
        ref="carSelect"
      >
        <el-option
          v-for="item in busOptions"
          :key="item.vehicleNo"
          :disabled="loading"
          :label="item.cph"
          :value="item.vehicleNo"
        ></el-option>
      </el-select>
    </div>
    <!-- 地图 -->
    <div id="container" class="myMap" @click="onSelectBlur"></div>
    <div class="mapHintCard">
      <div class="hintItem" v-for="(item, i) in hintOptions" :key="i">
        <p>
          <img :src="item.url" />
        </p>
        <p>{{ item.val }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  queryAllBusLineList,
  getBusBycompanyIds,
  getLineAndStationsByCompanyIds
} from "@/api/lib/bus-api";
import AMap from "AMap";
import { getBusInfo } from "@/api/lib/refreshAlarm";

export default {


  data () {
    return {
      keyword: "",
      hintStr: "请输入线路/车辆查找",
      pathObj: {},
      map: {},
      loading: false,
      showContent: false,
      lineOptions: [],
      busOptions: [],
      detailData: [],
      timer_bus: null, //动态车辆定时器
      reqstatus: 0, //定时器请求次数
      companyIds: [],
      busIds: [],
      companyIds_cache: [],
      busId: null,
      marker_bus: [],
      marker_label: [],
      colorArr: [],
      hintOptions: [
        { url: require("@/assets/images/busIcon/bus_map_nor.png"), val: "正常" },
        { url: require("@/assets/images/busIcon/bus_map_leave.png"), val: "离线" },
        { url: require("@/assets/images/busIcon/bus_map_err.png"), val: "维修" },
        { url: require("@/assets/images/busIcon/bus_map_aerate.png"), val: "加气" },
        { url: require("@/assets/images/busIcon/bus_map_offline.png"), val: "脱线" },
      ],
      stationIcon: [
        require('@/assets/images/bus/master.png'),
        require('@/assets/images/bus/slaver.png'),
      ],
      businessType: ["", '上', '下', '环'],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      text: null,//站点hover text
    };
  },

  methods: {
    onLineBlur () {
      this.$refs.lineSelect.blur();
    },
    onCarBlur () {
      this.$refs.carSelect.blur();
    },
    onSelectBlur () {
      this.$refs.lineSelect.blur();
      this.$refs.carSelect.blur();
    },
    loadmap () {
      let map = new AMap.Map("container", {
        center: [105.855405, 32.338245],
        zoom: 10
      });
      this.map = map;
    },

    loadMapTool () {
      let self = this;
      self.map.plugin(["AMap.ToolBar"], function () {
        self.toolBar = new AMap.ToolBar({
          visible: true,
          position: "RB",
          locate: true,
          liteStyle: true
        });
        self.map.addControl(self.toolBar);
      });
    },

    /* 获取所有线路 */
    queryAllBusLine () {
      queryAllBusLineList().then(res => {
        if (res.code === 1000) {
          if (res.data.length == 0) {
            this.lineOptions = [];
            this.$message.warning("当前无线路信息,请先添加线路");
          } else {
            this.lineOptions = res.data;
            this.companyIds = [res.data[0].companyId];

            this.changeLine();
          }
        }
      });
    },

    /* 根据线路查询车辆（多选） */
    changeLine () {
      if (this.companyIds.length == 0) {
        this.busOptions = [];
        this.busIds = [];
        this.clearBusMarker();
      } else {
        this.busIds = [];
        getBusBycompanyIds({ companyIds: this.companyIds }).then(res => {
          if (res.data.length > 0) {
            let carData = []
            res.data.map((value) => carData = [...carData, ...value.vehicleList])
            this.busOptions = carData;

            this.loading = true;
            this.getLineAndStations();
            this.showBus();
            this.marker_bus = [];
            this.marker_label = [];
          }
        });
      }
    },

    /* 获取站点和线路信息 */
    getLineAndStations () {
      var self = this;
      self.clearBusMarker();
      getLineAndStationsByCompanyIds({ companyIds: this.companyIds }).then(res => {
        self.drawStation(res);
        self.drawPath(res);
        setTimeout(function () {
          self.loading = false;
        }, 1000);
      });

    },
    /**清除地图上显示和缓存车辆marker数据 */
    clearBusMarker () {
      this.reqstatus = 0;
      clearInterval(this.timer_bus);
      this.map.clearMap();
      this.marker_bus = [];
      this.marker_label = [];
      this.map.remove(this.marker_label);
      this.map.remove(this.marker_bus);
    },
    /* 绘制线路 */
    drawPath (res) {
      for (let i = 0; i < res.data.length; i++) {
        let element = res.data[i]
        if (element.lineMappingList && element.lineMappingList.length > 0) {
          for (let j = 0; j < element.lineMappingList.length; j++) {
            const value = element.lineMappingList[j];
            if (value.coordinates.indexOf("[{") > -1) {
              let { color, width, pellucidity } = value
              let data = JSON.parse(value.coordinates);
              this.renderPolyline(data, color, width, pellucidity)
            }
          }
        }
      }
    },
    //上下行线路数据
    renderPolyline (pathArr, color, width, pellucidity) {
      var polyline = new AMap.Polyline({
        // 设置线覆盖物路径
        path: pathArr,
        // 线颜色
        strokeColor: color || "#5298ff",
        strokeOpacity: pellucidity || 0.8,
        // 线宽
        strokeWeight: width || 6,
        strokeDasharray: [25, 10],
        lineJoin: "round",
        lineCap: "round",
        cursor: "default",
      });

      this.map.add([polyline]);
      this.map.setFitView();
    },

    /* 绘制站点 */
    drawStation (res) {
      for (let i = 0; i < res.data.length; i++) {
        let element = res.data[i];
        let flag = false
        //下行
        if (element.downStationList) {
          flag = true
          this.renderStation(element.downStationList)
        }
        //上行
        if (element.upStationList) {
          flag = true
          this.renderStation(element.upStationList)
        }

        if (flag) {
          let startMarker = new AMap.Marker({
            position: [element.masterLongitude, element.masterLatitude],
            icon: this.stationIcon[0],
            offset: new AMap.Pixel(-5, -4),
            zIndex: 15
          });
          let endMarker = new AMap.Marker({
            position: [element.slaverLongitude, element.slaverLatitude],
            icon: this.stationIcon[1],
            offset: new AMap.Pixel(-5, -4),
            zIndex: 15
          });
          this.map.add([startMarker, endMarker]);
        }


      }
    },
    /* 绘制站点 */
    renderStation (stationArr, moveType) {
      let self = this;
      stationArr.map((item, i) => {
        let content = ''
        if (item.latitude) {
          content = `<div style='width:5px;height:5px;border-radius:50%;background-color:#FFFFFF;border:2px solid #0B4FD5'></div>`
        }
        let marker = new AMap.Marker({
          content: content,
          position: [item.longitude, item.latitude],
          offset: new AMap.Pixel(-5, -4),
          zIndex: 15
        });
        marker.on('mouseover', function (e) {
          self.text = new AMap.Text({
            map: self.map,
            position: [item.longitude, item.latitude],
            text: item.stationName,
            offset: new AMap.Pixel(0, -12),
          })
        })
        marker.on('mouseout', function (e) {
          self.map.remove(self.text)
        })
        /**
         * 公交2.0迭代：站点名称显示出来
         */        
        marker.setMap(self.map);
      });

    },

    closeDialog (e) {
      this.showContent = false;
    },


    changeBus () {
      this.clearBusMarker();
      this.getLineAndStations();
      this.showBus();
    },

    /* 展示车辆 */
    showBus () {
      let self = this;
      if (self.timer_bus) {
        self.clearBusMarker();
      }
      let timerFn_bus = function () {
        getBusInfo({ companyIds: self.companyIds, vehicleNos: self.busIds }).then(res => {
          if (res.data.length > 0) {            
            //根据经纬度绘制车辆
            self.renderCarMarker(res.data);
          }
        });
      };

      timerFn_bus();
      self.reqstatus += 1;
      self.timer_bus = setInterval(() => {
        timerFn_bus();
      }, 10000);
    },
    /**显示200及以下个车辆信息 */
    renderCarMarker (points) {
      let self = this;
      for (let i = 0; i < points.length; i += 1) { 
          let lnglat = new AMap.LngLat(points[i].longitude, points[i].latitude);
        let { businessType, status, cph } = points[i]
        let content = `<div style="display:flex;justify-content: space-around;align-items: center;">
        <span>${cph}</span>
        <span style="width: 22px;height:22px;margin-left:5px;text-align:center;color:#333;background: #f78d4233;">${self.businessType[businessType]?self.businessType[businessType]:''}</span></div>`
        //车辆makerMove
        if (self.marker_bus[i] && self.reqstatus >= 1) {
          self.marker_bus[i].moveTo(lnglat, 5000, function (k) {
            return k;
          });
          const newIcon = new AMap.Icon({
            size: new AMap.Size(26, 26), // 图标尺寸
            image: self.hintOptions[status].url, // Icon的图像
            imageSize: new AMap.Size(26, 26)
          });
          self.marker_bus[i].setIcon(newIcon);
          self.marker_label[i].moveTo(lnglat, 5000, function (k) {
            return k
          })
          self.marker_label[i].setText(content)
        } else {
          //车辆makerSet
          let marker = new AMap.Marker({
            icon: new AMap.Icon({
              image: self.hintOptions[status].url, // Icon的图像
              imageSize: new AMap.Size(26, 26)
            }),
            zIndex: 999,
            topWhenClick: true,
            position: lnglat,
            offset: new AMap.Pixel(-10, -5),
          });
          let text = new AMap.Text({
            map: self.map,
            position: lnglat,
            text: content,
            offset: new AMap.Pixel(75, -2),
            style: {
              'font-size': '14px',
              border: 'none',
              padding: '6px 10px',
              'background-color': '#fff',
              'box-shadow': '0px 2px 8px 0px rgba(200, 201, 204, 0.5)',
              'line-height': '20px'
            }
          })
          let mask = points[i];
          //console.log(3333,points[i]);//经纬度没变
          text.on('click', function (e) {
            // 展示当前点击车辆信息
            self.showCarDetail(mask,[e.lnglat.lng,e.lnglat.lat])
          })
          marker.on('click', (e)=>{
            // console.log('mask222',mask)
            // 展示当前点击车辆信息
            self.showCarDetail(mask,[e.lnglat.lng,e.lnglat.lat])
          })
          self.marker_label.push(text)
          marker.setMap(self.map);
          self.marker_bus.push(marker);
        }
      }
    },
    //显示车辆详细信息
    showCarDetail (point,lnglat) {
      // console.log(2222,[point.longitude, point.latitude])
      let content = `<div>车牌号：<span>${point.cph}</span></div>
                    <div>设备号：<span>${point.deviceNo}</span></div>
                    <div>司机：<span>${point.driverName}</span></div>
                    <div>当前行驶速度：<span>${point.velocity}km/h</span></div>
                    <div>行驶角度：<span>${point.direction}</span></div>
                    <div>位置最后更新时间：<span>${point.gpsTime}</span></div>
                    <div>调度屏线路：<span>${point.pathID}</span></div>
                    `
      let infoWindow = new AMap.InfoWindow({
        anchor: "bottom-left",
        content: `<div class=trailBoard>${content}</div> `
      })
      // infoWindow.open(this.map, [point.longitude, point.latitude]);
      infoWindow.open(this.map,lnglat);
    },

  },
  mounted () {
    this.queryAllBusLine();
    this.loadmap();
    this.loadMapTool();
  },


  destroyed () {
    clearInterval(this.timer_bus);
  }
};
</script>

<style lang='scss' scoped>
.electronicMap {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;

  .carDialogVue {
    position: absolute;
    width: 350px !important;
    border: 1px;
    top: 21%;
    right: 4%;
    z-index: 2000;
    .el-card {
      border: none !important;
      background: white;
      box-shadow: 2px 2px 2px 2px #cacaca;
      .el-card__header {
        border-bottom: 1px solid #8b97b1 !important;
        padding: 0 !important;
        .clearfix {
          font-size: 16px;
          padding: 10px;
        }
      }
      .el-card__body {
        padding-top: 5px !important;
      }
    }

    .monitor-message {
      overflow: hidden;
      .el-card__body {
        padding: 8px;
      }
      .cars-info {
        position: relative;
        .info {
          margin-bottom: 5px;
          li {
            height: 23px;
            line-height: 23px;
            font-size: 14px;
            color: #666666;
            letter-spacing: -0.34px;
            span {
              display: inline-block;
              width: 68px;
              font-size: 14px;
              letter-spacing: -0.34px;
              white-space: nowrap;
            }
            p {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }

  .selectBox {
    margin-left: 20px;
    margin-top: 20px;
    position: absolute;
    z-index: 100;
  }

  .mapHintCard {
    position: absolute;
    background: #fff;
    z-index: 100;
    right: 8%;
    bottom: 5%;
    width: 13vw;
    height: 8vh;
    padding: 0 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 3%;
    .hintItem {
      color: #333333;
      font-size: 1.5vh;
      line-height: 20px;
      font-weight: 400;
      text-align: center;
      img {
        width: 2.5vh;
        height: 2.5vh;
      }
    }
  }

  .amap-zoom-touch-plus > div,
  .amap-zoom-touch-minus > div {
    font-size: 16px;
  }

  .amap-touch-toolbar .amap-geo {
    background-size: 13px;
  }

  .amap-marker-label {
    border: none !important;
    border-radius: 6px;
    padding: 10px;
    box-shadow: 3px 3px 6px #ccc;
    z-index: 300;
    p {
      line-height: 20px;
    }
  }
  // .amap-marker-label{
  //           border: 0;
  //           background-color: transparent;
  //       }

  .amap-marker-label:after {
    position: absolute;
    border: 20px solid transparent;
    border-bottom-color: #fff;
    bottom: -4px;
    left: -29%;
    content: "";
    width: 12px;
    height: 0;
    z-index: -1;
    transform: rotate(-65deg);
  }

  #container {
    height: 100%;
  }

  .el-table__body {
    tr:nth-child(even) {
      background-color: #f9fafd;
    }
    tr:nth-child(odd) {
      background-color: #ecf5ff;
    }
  }
}
.theme-project-bus {
  .electronicMap {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
